import React from "react";
import ReactDOMClient from "react-dom/client";
import { App } from "./App.jsx";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import rootReducer, { rootSaga } from "./modules";
// import logger from "redux-logger";                              // for debug
// import { composeWithDevTools } from "redux-devtools-extension"; // for debug
import { thunk } from "redux-thunk";
import { BrowserRouter } from "react-router-dom";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { AuthProvider } from "./contexts/auth/auth.context.js";
import AlertDialog from './components/alert/AlertDialog.jsx';
import ConfirmDialog from './components/confirm/ConfirmDialog.jsx';

const customHistory = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware({
  context: {
    history: customHistory,
  },
}); // 사가 미들웨어를 만듭니다.

const store = createStore(
  rootReducer, 
  applyMiddleware(thunk, sagaMiddleware,),                                // for release
  // logger 를 사용하는 경우, logger가 가장 마지막에 와야합니다.
  // composeWithDevTools(applyMiddleware(thunk, sagaMiddleware, logger))  // for debug
); // 여러개의 미들웨어를 적용 할 수 있습니다.

sagaMiddleware.run(rootSaga); // 루트 사가를 실행해줍니다.

// 글로벌 변수
let global = { 
  menuCategoryLevel: 0, 
  menuCategoryCode: 'all', 
  menuCategoryName: '전체', 
  submenuCategoryCode: '',
  submenuCategoryName: ''
};

// 유저 정보 불러드림
let user = localStorage.getItem("user");
user = user ? JSON.parse(user) : JSON.parse({id: 0, email: '', access: '', refresh: ''});

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(
  <AlertDialog>
    <ConfirmDialog>
      <AuthProvider userData={user} globalData={global}>
          <Provider store={store}>
              <App />
          </Provider>
      </AuthProvider>
    </ConfirmDialog>
  </AlertDialog>
);
